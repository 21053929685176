<template>
  <div class="detail-community-coop board" :class="{skeleton : !state.loaded }">
    <template v-if="state.coop.detail.title">
      <div class="detail part" ref="detailRef">
        <div class="article">
          <div class="top clearfix">
            <div class="title font-md">{{ state.coop.detail.title }}</div>
            <div class="float-left font-sm">{{ state.coop.detail.memberName }}</div>
            <div class="float-right font-sm">{{ state.coop.detail.createDate }}</div>
          </div>
          <div class="content" v-html="state.coop.detail.content"></div>
        </div>
      </div>
      <div class="comment part">
        <div class="form">
          <label :for="`${component.name}Content`" class="title">댓글 남기기</label>
          <div class="input">
            <div class="textarea">
              <textarea :id="`${component.name}Content`" class="form-control" placeholder="댓글을 남겨주세요."></textarea>
            </div>
            <button class="btn btn-secondary font-sm" @click="addComment()">등록</button>
          </div>
        </div>
        <ul class="tight list" v-if="state.coop.detail.commentList && state.coop.detail.commentList.length">
          <li class="font-sm" v-for="(c, idx) in state.coop.detail.commentList" :key="idx">
            <Comment
                :content="c.content"
                :createDate="c.createDate"
                :depth="c.depth"
                :edit="editComment"
                :idx="idx"
                :memberSeq="c.memberSeq"
                :memberName="c.memberName"
                :memberAuth="c.memberAuth"
                :remove="removeComment"
                :skeleton="!state.loaded"
            />
          </li>
        </ul>
      </div>
    </template>
    <div class="latest part">
      <div class="title"><span>협업 지원 목록</span></div>
      <ul class="tight" v-if="Array.isArray(state.coop.list) && state.coop.list.length">
        <li class="pointer" :class="{ active: state.loaded && n.crowdsourcingSeq === state.coop.detail.crowdsourcingSeq }" v-for="(n, idx) in state.coop.list" :key="idx" @click="open(n.crowdsourcingSeq)">
          <div class="wrapper">
            <span class="title">{{ n.title }}</span>
            <div class="bottom">
              <span class="date">{{ n.createDate }}</span>
              <span class="color-secondary" v-if="n.commentList && n.commentList.length">댓글 {{ n.commentList.length }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-else>
        <NoData/>
      </div>
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="load" v-if="state.paginationInfo"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";
import MemberPicture from "@/components/MemberPicture";
import Comment from "@/components/Comment.vue";

function Component(initialize) {
  this.name = "pageProjectDetailCoop";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, MemberPicture, NoData, Pagination,},
  props: {
    projectSeq: Number
  },
  setup(props) {
    const component = new Component(() => {
      load(null, () => {
        state.coop.list.length && open(state.coop.list[0].crowdsourcingSeq);
      });
    });

    const state = reactive({
      args: {},
      paginationInfo: null,
      loaded: false,
      coop: {
        detail: {
          crowdsourcingSeq: "",
          title: "",
          memberName: "",
          createDate: "",
          content: "",
          commentList: []
        },
        list: [],
      },
    });

    const detailRef = ref();

    const open = (seq) => {
      if (!state.loaded) {
        return;
      }

      state.coop.detail = state.coop.list.find(c => c.crowdsourcingSeq === seq);

      if (detailRef && detailRef.value) {
        const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        window.scrollTo({
          top: curScrollTop + detailRef.value.getBoundingClientRect().top - 66,
          left: 0,
          behavior: "smooth"
        });
      }
    };

    const loadComments = () => {
      load(null, () => {
        open(state.coop.detail.crowdsourcingSeq);
      });
    };

    const addComment = () => {
      const $content = document.getElementById(`${component.name}Content`);

      const args = {
        crowdsourcingSeq: state.coop.detail.crowdsourcingSeq,
        content: $content.value?.trim(),
      };

      if (!args.content) {
        $content.focus();
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      } else if (lib.getBytes(args.content) > 500) {
        $content.focus();
        return store.commit("setSwingMessage", "내용의 길이가 너무 깁니다. 500Byte 이하로 작성해주세요.");
      }

      http.post(`/api/reward/projects/${props.projectSeq}/coop-comments`, args).then(() => {
        $content.value = "";
        loadComments();
        store.commit("setSwingMessage", "댓글을 등록하였습니다.");
      }).catch(httpError());
    };

    const onCommentEdited = ({commentSeq, content}) => {
      const args = {
        content,
        updateId: store.state.account.memberSeq,
      };

      http.patch(`/api/reward/projects/${props.projectSeq}/coop-comments/${commentSeq}`, args).then(() => {
        store.commit("setSwingMessage", "댓글을 수정하였습니다.");
        store.commit("closeModal", {name: "Comment"});
        loadComments();
      }).catch(httpError());
    };

    const editComment = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          content: comment.content,
          maxByte: 500,
        },
        callback: `${component.name}.onCommentEdited`,
      });
    };

    const removeComment = (commentSeq) => {
      store.commit("confirm", {
        message: "선택하신 댓글을 삭제하시겠습니까?",
        allow() {
          const args = {
            updateId: store.state.account.memberSeq
          };

          http.delete(`/api/reward/projects/${props.projectSeq}/coop-comments/${commentSeq}`, args).then(() => {
            store.commit("setSwingMessage", "선택하신 댓글을 삭제하였습니다.");
            loadComments();
          });
        }
      });
    };

    const load = (num, func) => {
      state.loaded = num ? true : false;
      state.args = {
        pageIndex: num ? num : 1,
        pageUnit: 5,
      };

      for (let i = 0; i < 5; i += 1) {
        state.coop.list.push({
          title: "Wait a moment",
          memberName: "Wait",
          createDate: "0000-00-00 00:00"
        });
      }

      http.get(`/api/reward/projects/${props.projectSeq}/coop`, state.args).then(({data}) => {
        state.loaded = true;
        state.paginationInfo = data.body.paginationInfo;
        state.coop.list = data.body.rewardCoopList;
        typeof func === "function" && func();
      });
    };

    return {component, state, detailRef, open, addComment, editComment, removeComment, load, onCommentEdited};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community-coop.skeleton {
  .latest ul > li > .wrapper {
    > span, .bottom span {
      @include skeleton;
    }
  }
}
</style>