<template>
  <div class="reward-list">
    <ul class="tight">
      <li v-for="(item, idx) in items" :key="idx">
        <div class="wrapper" :class="{'pointer border-hover-point': getLink(item, idx) || $lib.isPreviewPage(), 'sold-out': isRewardSoldOut(item.rewardQty, item.extraCount)}"
             @click="openRewards(item.rewardSeq, item.rewardQty, item.extraCount)">
          <div class="main">
            <div class="count color-point font-sm">
              <span class="img mr-1" style="background-image: url(/assets/ico/common.smile.point.svg)"></span>
              <span class="align-middle">{{ $lib.getNumberFormat(item.joinCount) }}명 후원</span>
            </div>
            <div class="amount">
              <b class="font-md bold" v-if="item.rewardSeq">{{ item.rewardAmt ? $lib.getNumberFormat(item.rewardAmt) + "원+" : "(N/A)" }}</b>
              <b class="font-md bold" v-else>리워드 없이 참여</b>
              <template v-if="Number(item.rewardQty) > 0">
                <span class="badge badge-danger-soft" v-if="!isRewardSoldOut(item.rewardQty, item.extraCount)">{{ $lib.getNumberFormat(item.extraCount) }}개 남음</span>
                <span class="badge badge-secondary-soft" v-else>선착순 마감</span>
              </template>
            </div>
          </div>
          <div class="title">
            <span v-if="item.rewardSeq">{{ item.title }}</span>
            <span v-else>이 프로젝트를 후원합니다.</span>
          </div>
          <div class="delivery font-sm" v-if="item.rewardExpectText">
            <div class="subject">
              <span class="img mr-1 align-middle" style="background-image: url(/assets/ico/common.check.svg)"></span>
              <b class="align-middle">배송일</b>
            </div>
            <span>{{ item.rewardExpectText }}</span>
          </div>
          <!--          <template v-if="!item.rewardSeq || Number(item.rewardQty) === 0 || item.extraCount > 0">-->
          <!--            <a class="btn btn-point-soft font-sm bold" v-if="accessible">-->
          <!--              <b>후원하기</b>-->
          <!--            </a>-->
          <!--          </template>-->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageProjectDetailRewards";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    projectName: String,
    accessible: Boolean,
    items: Array
  },
  setup(props) {
    const component = new Component(() => {
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const isRewardSoldOut = (rewardQty, extraCount) => {
      return Number(rewardQty) > 0 && Number(extraCount) <= 0;
    };

    const openRewards = (rewardSeq, rewardQty, extraCount) => {
      if (lib.isPreviewPage()) {
        return window.alert(definitions.messages.noSupportInPreview);
      }

      if (isRewardSoldOut(rewardQty, extraCount)) {
        return;
      }

      store.commit("openModal", {
        name: "Rewards",
        params: {
          projectSeq: projectSeq,
          projectName: props.projectName,
          rewardSeq,
        }
      });
    };

    const getLink = (item) => {
      if (!lib.isPreviewPage() && (!item.rewardSeq || Number(item.rewardQty) === 0 || item.extraCount > 0) && props.accessible) {
        return `/reward/${projectSeq}/order?rewardSeq=${item.rewardSeq || ""}`;
      }

      return undefined;
    };

    return {component, isRewardSoldOut, getLink, openRewards};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.reward-list {
  ul {
    li {
      margin-bottom: $px16;

      .wrapper {
        background: #fff;
        border: $px1 solid #f2f2f2;
        border-radius: $px24;
        display: flex;
        flex-direction: column;
        gap: $px16;
        padding: $px24 $px20;
        text-decoration: none;
        transition: border-color 0.18s;

        .main {
          .count {
            > .img {
              width: $px16;
              height: $px16;
              vertical-align: middle;
            }
          }

          .amount {
            margin-top: $px8;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .delivery {
          display: flex;
          align-items: flex-start;
          gap: $px16;

          .subject {
            flex-shrink: 0;

            > .img {
              width: $px16;
              height: $px16;
            }
          }
        }

        > .btn {
          border-radius: $px12;
          padding: $px15;
        }

        &.sold-out {
          cursor: initial;
          background: $colorBackground;
          color: $colorSecondary;

          .main {
            .count {
              color: $colorSecondary !important;

              > .img {
                background-image: url(/assets/ico/common.smile.svg) !important;
              }
            }
          }

          > .btn {
            display: none;
          }

          &:hover {
            cursor: initial;
            border-color: #f2f2f2 !important;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>