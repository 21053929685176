<template>
  <div class="detail" :class="{skeleton : !state.loaded }" :data-project-type="projectType" :data-tab="state.tab.list[state.tab.idx] ? state.tab.list[state.tab.idx].name : ''">
    <div class="wrapper">
      <div class="summary">
        <div class="container">
          <div class="info">
            <div class="left">
              <div class="wrapper shadow">
                <div class="video" v-if="state.project.videoFlag === 'Y'">
                  <iframe :src="state.project.videoUrl" title="유튜브 플레이어" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="slider represent swiper" ref="slider1Ref" v-else>
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(t, idx) in state.project.thumbnails" :key="idx">
                      <span class="img" :style="{backgroundImage: `url('${$store.getters.thumbnailUrl(t.thumbFilePath, {origin: state.project.originThumbnailYn === 'Y'})}')`, backgroundPosition: state.project.mainImagePosition}"></span>
                    </div>
                  </div>
                  <div class="pagination" :id="`${component.name}ThumbPagination`" v-if="state.project.thumbnails && state.project.thumbnails.length > 1"></div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="top">
                <div class="category">
                  <router-link :to="state.project.projectCate ? `/${projectType}/list?category=${state.project.projectCate}` : ''" class="no-underline font-sm" v-if="state.project.projectCate">
                    <i class="fa fa-angle-left align-middle pr-2"></i>
                    <span class="align-middle">{{ state.project.projectCateName }}</span>
                  </router-link>
                  <div class="types" v-if="projectType === 'invest'">
                    <span v-if="state.project.stockTypeText">{{ state.project.stockTypeText }}</span>
                    <span v-if="state.project.fundingType === '02'">사모</span>
                    <span v-if="state.project.rewardFlag === 'Y'">리워드</span>
                    <span v-if="state.project.incomeDeductionFlag === 'Y'">소득공제</span>
                  </div>
                  <div class="tags d-none d-lg-block" v-if="projectType !== 'invest' && ((state.project.tags && state.project.tags.length) || !state.loaded)">
                    <ul class="tight no-scrollbar" v-if="state.loaded">
                      <li v-for="(t, idx) in state.project.tags" :key="idx">
                        <router-link class="font-xs no-underline" :to="`/main/search?searchKeyword=${encodeURIComponent(t.replace('#', ''))}`">{{ t }}</router-link>
                      </li>
                      <li v-if="state.project.adultFlag === 'Y'">
                        <b class="color-danger font-xs">19세 이상</b>
                      </li>
                    </ul>
                    <ul class="tight" v-else>
                      <li v-for="(i) in 5" :key="i">
                        <a class="font-xs">Wait</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="project-name">
                  <span>{{ state.project.projectName || "(제목 없음)" }}</span>
                  <span class="pointer pl-2 color-secondary font-lg" title="관리자용 프로젝트 정보" @click="openProjectDetailInfoForAdmin()"
                        v-if="['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth) && state.loaded">
                    <i class="fa fa-info-circle"></i>
                  </span>
                </div>
                <div class="callout">
                  <span class="font-sm d-block">{{ projectType === "invest" ? "투자 금액" : "모인 금액" }}</span>
                  <b v-if="state.mockFlag === 'Y' && state.contestInfo.projectStateHideYn === 'Y'">비공개</b>
                  <div class="amount font-base d-flex justify-content-between" v-else>
                    <div>
                      <b>{{ state.loaded ? $lib.getNumberFormat(projectType === "invest" ? state.project.subscribeAmount : state.project.expenseAmt) : "0000000" }}</b>
                      <span class="font-sm">{{ computedCurrencyUnit }} </span>
                    </div>
                    <b class="percent color-point font-lg">{{ $lib.getNumberFormat(projectType === "invest" ? state.project.subscribeRate : state.project.per) }}%</b>
                  </div>
                  <div class="graph" v-if="Number(state.project.progressOrder) === 1">
                    <div
                        class="progress-bar bg-point"
                        role="progressbar"
                        :style="{ width: (projectType === 'invest' ? state.project.subscribeRate : state.project.per > 100 ? 100 : projectType === 'invest' ? state.project.subscribeRate : state.project.per) + '%' }"
                        aria-label="진행 표시줄"
                        :aria-valuenow="projectType === 'invest' ? state.project.subscribeRate : state.project.per"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="cheer font-base">
                    <b v-if="state.mockFlag === 'Y' && state.contestInfo.projectStateHideYn === 'Y'">비공개</b>
                    <template v-else>
                      <span class="img align-middle mr-1" style="background-image: url(/assets/ico/page.project.detail.invest.chart-up.svg)" v-if="projectType === 'invest'"></span>
                      <b class="color-point align-middle">{{ state.loaded ? $lib.getNumberFormat(projectType === "reward" ? state.project.investorCnt : state.project.subscribeCount) : "0000000" }}</b>
                      <span class="align-middle">명이 {{ projectType === "invest" ? "투자" : "후원" }}했어요</span>
                    </template>
                  </div>
                </div>
                <ul class="tight d-flex d-lg-none flex-wrap" v-if="projectType !== 'invest' && state.project.tags && state.project.tags.length">
                  <li class="font-xs py-2 px-3 bg-light rounded-pill mr-1 mb-1" v-for="(t, idx) in state.project.tags" :key="idx">
                    <router-link class="font-xs no-underline text-nowrap" :to="`/main/search?searchKeyword=${encodeURIComponent(t.replace('#', ''))}`">{{ t }}</router-link>
                  </li>
                </ul>
                <div class="organize">
                  <div class="d-flex">
                    <div class="subject">
                      <img src="/assets/ico/common.check.svg" alt="체크"/>
                      <b>목표 금액</b>
                    </div>
                    <span>{{ projectType === "invest" ? $lib.getNumberFormat(state.project.totAmount) : $lib.getNumberFormat(state.project.targetAmt) }}원</span>
                  </div>
                  <template v-if="projectType === 'invest'">
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>마감일</b>
                      </div>
                      <span>{{ state.project.fundingEndDate }}</span>
                    </div>
                    <div class="more">
                      <div class="child collapse" :id="`${component.name}InvestSubInfo`">
                        <div class="wrapper">
                          <div class="d-flex">
                            <div class="subject">
                              <img src="/assets/ico/common.check.svg" alt="체크"/>
                              <b>청약 가능 시간</b>
                            </div>
                            <span>05시 - 23시</span>
                          </div>
                          <div class="d-flex" v-if="state.project.tags && state.project.tags.length">
                            <div class="subject">
                              <img src="/assets/ico/common.check.svg" alt="체크"/>
                              <b>해시태그</b>
                            </div>
                            <ul class="tight d-flex flex-wrap">
                              <li class="font-sm mr-3 mb-1" v-for="(t, idx) in state.project.tags" :key="idx">
                                <router-link class="no-underline d-inline-block text-nowrap color-anchor" :to="`/main/search?searchKeyword=${encodeURIComponent(t.replace('#', ''))}`">#{{ t.replace("#", "") }}</router-link>
                              </li>
                            </ul>
                          </div>
                          <span class="font-xs color-secondary mt-2">일반 투자자</span>
                          <div class="d-flex">
                            <div class="subject">
                              <img src="/assets/ico/common.check.svg" alt="체크"/>
                              <b>연간 투자 한도</b>
                            </div>
                            <span>1,000만원</span>
                          </div>
                          <div class="d-flex">
                            <div class="subject">
                              <img src="/assets/ico/common.check.svg" alt="체크"/>
                              <b>기업당 투자 한도</b>
                            </div>
                            <span>500만원</span>
                          </div>
                        </div>
                      </div>
                      <a class="font-sm color-secondary no-underline collapsed" data-toggle="collapse" :href="`#${component.name}InvestSubInfo`" role="button">
                        <i class="fa fa-angle-up"></i>
                      </a>
                    </div>
                  </template>
                  <template v-else-if="state.mockFlag === 'Y'">
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>모의투자 한도</b>
                      </div>
                      <span>{{ $lib.getNumberFormat(state.contestInfo.projectPossibleLimit) }}{{ computedCurrencyUnit }}</span>
                    </div>
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>모의투자한 금액</b>
                      </div>
                      <span>{{ $lib.getNumberFormat(state.contestInfo.myProjectInvestAmt) }}{{ computedCurrencyUnit }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>펀딩 기간</b>
                      </div>
                      <div>
                        <span>{{ state.project.investStartDate }} ~ {{ state.project.investEndDate }}</span>
                        <div class="badge badge-danger-soft ml-2 align-middle" v-if="projectType === 'reward'">
                          <template v-if="computedIsProgressing">
                            <span v-if="state.project.dday === '0'">오늘 마감</span>
                            <span v-else-if="state.project.fundingEndDate">{{ state.project.fundingEndDate }} 마감</span>
                            <span v-else>{{ $lib.getNumberFormat(state.project.dday || 0) }}일</span>
                          </template>
                          <span v-else>마감</span>
                        </div>
                        <div class="badge badge-danger-soft" v-else>
                          <span v-if="state.project.dDay === '0'">오늘 마감</span>
                          <b v-else>{{ $lib.getNumberFormat(state.project.dDay) || "0" }}일</b>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>결제 시점</b>
                      </div>
                      <span v-if="state.project.fundingType === 'A'">프로젝트 성공 시 {{ state.project.payDate }}에 진행</span>
                      <span v-else>후원 시 즉시 결제</span>
                    </div>
                    <div class="d-flex">
                      <div class="subject">
                        <img src="/assets/ico/common.check.svg" alt="체크"/>
                        <b>펀딩 방식</b>
                      </div>
                      <div class="dropdown">
                        <span>{{ state.project.fundingType === "A" ? "성공해야 리워드" : "무조건 리워드" }}</span>
                        <a class="no-underline" type="button" data-toggle="dropdown" aria-expanded="false">
                          <i class="fa fa-question-circle-o color-secondary"></i>
                        </a>
                        <div class="dropdown-menu">
                        <span v-if="state.project.fundingType === 'A'">
                          목표금액이 100% 이상 달성되어야 프로젝트 참여건에 대한 예약 결제가 진행됩니다. 목표금액을 달성하지 못한 경우 결제가 진행되지 않으며, 리워드를 제공하지 않습니다.
                        </span>
                          <span v-else>프로젝트 참여와 동시에 결제가 진행되며, 목표 금액을 달성하지 못하더라도 예정된 리워드가 제공됩니다.</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="actions">
                <button class="btn btn-default" @click="toggleInterest()" title="관심 프로젝트">
                  <span class="img" :style="{backgroundImage: `url(/assets/ico/common.heart${$store.state.interests.sequences[projectType].includes(projectSeq) ? '.fill.svg' : '.svg'})`}"></span>
                  <span class="font-xs">{{ $lib.getNumberFormat(state.project.interestCnt) }}</span>
                </button>
                <ProjectDetailJoinBtn
                    class="submit"
                    :loaded="state.loaded"
                    :projectType="projectType"
                    :progressOrder="Number(state.project.progressOrder)"
                    :submit="submit"
                    :investSubmit="investSubmit"
                    :mockFlag="state.mockFlag"
                    :successFailFlag="state.project.successFailFlag"
                    :subscribeRate="state.project.subscribeRate"
                    :endFlag="state.project.endFlag"
                    :displayStateName="state.project.displayStateName"
                    :subscribeAmount="Number(state.project.subscribeAmount)"
                    :totAmount="Number(state.project.totAmount)"
                />
                <button class="btn btn-default" title="공유하기" @click="openShareModal()">
                  <span class="img" style="background-image: url(/assets/ico/common.share.svg);"></span>
                  <span class="font-xs">{{ state.project.share > 999 ? "999+" : state.project.share }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="alert font-sm" v-if="projectType === 'invest'">
            <span>증권형 크라우드펀딩에 대한 투자는 투자원금의 손실이 발생할 수 있습니다. 투자에 대한 책임은 전적으로 투자자 본인에게 있으므로, 투자위험에 대한 내용을 꼭 확인해주세요. </span>
            <b class="pointer underline d-block d-lg-inline-block ml-lg-2 mt-lg-0 mt-2" @click="$store.commit('openModal', { name: 'Info', params: { category: 'invest' }})">자세히보기</b>
          </div>
          <MakerInfo
              componentNameSuffix="mobile"
              :builderSeq="state.project.openId || state.project.memberSeq || state.project.builderSeq"
              :projectType="projectType"
              class="d-lg-none"
              v-if="state.mockFlag !== 'Y'"
          />
        </div>
      </div>
      <div class="content" ref="contentRef" v-if="state.loaded">
        <div class="bg-black" v-if="state.expand" @click="toggleExpand(false)"></div>
        <div class="wrapper" ref="contentWrapperRef">
          <div :id="`${component.name}Tabs`" class="tabs">
            <div class="container">
              <div class="wrapper">
                <ul class="tight nav nav-tabs no-scrollbar">
                  <li class="nav-item" v-for="(t, idx) in state.tab.list" :key="idx">
                    <a class="btn no-underline nav-link pointer" :class="{ 'active shadow': idx === state.tab.idx }" @click="pushRouterTabQuery(t.name)">
                      <b class="title">{{ t.title }}</b>
                      <b class="color-point font-xs pl-1" v-if="t.count > 0">{{ t.count > 99 ? "99+" : t.count.toString() }}</b>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div ref="scrollRef"></div>
          <div class="container">
            <div class="row">
              <div class="article" :class="projectType === 'invest' || state.mockFlag === 'Y' ? 'col-lg-12' : 'col-lg-8'">
                <template v-if="projectType === 'reward'">
                  <div class="intro wrapper" v-show="state.tab.idx === 0">
                    <DetailIntro :projectType="projectType" :projectSeq="projectSeq" :newEditorFlag="state.project.newEditorFlag" :simulationFlag="state.mockFlag" :rewardItems="state.rewardItems" :openId="state.project.openId"
                                 ref="detailIntroRef"/>
                  </div>
                  <div class="wrapper">
                    <DetailCommunity :projectType="projectType" :projectSeq="projectSeq" :qnaCount="state.qnaCount" :newsCount="state.newsCount" :reviewCount="state.project.reviewCount" :investRewards="state.project.investRewards"
                                     :investEndDate="state.project.investEndDate" :openId="state.project.openId"
                                     v-if="state.tab.idx === 1"/>
                    <DetailCheers :projectType="projectType" :projectSeq="projectSeq" :investorNameOpenYn="state.contestInfo ? state.contestInfo.investorNameOpenYn : ''"
                                  :investorNameAltText="state.contestInfo ? state.contestInfo.investorNameAltText : ''" :currencyUnit="computedCurrencyUnit"
                                  v-else-if="state.tab.idx === 2"/>
                    <DetailCoop :projectSeq="projectSeq" v-else-if="state.tab.idx === 3"/>
                  </div>
                </template>
                <template v-else>
                  <div class="wrapper">
                    <DetailInvestorRelations
                        :project="state.project"
                        :projectType="projectType"
                        :projectSeq="projectSeq"
                        :fixed="state.fixed"
                        :investSubmit="investSubmit"
                        :submit="submit"
                        v-if="state.tab.idx === 0"/>
                    <DetailCommunity :projectType="projectType" :projectSeq="projectSeq" :qnaCount="state.qnaCount" :newsCount="state.newsCount" v-if="state.tab.idx === 1"
                                     :openId="state.project.memberSeq"/>
                    <DetailInvestors :projectSeq="projectSeq" :fundType="state.project.fundType" v-if="state.tab.idx === 2"/>
                  </div>
                </template>
              </div>
              <div class="right col-lg-4" v-if="state.rewardItems.length && state.mockFlag !== 'Y'">
                <div class="wrapper thin-scrollbar" :class="{expand: state.expand}">
                  <MakerInfo
                      componentNameSuffix="desktop"
                      class="d-none d-lg-block"
                      :builderSeq="state.project.openId || state.project.memberSeq || state.project.builderSeq"
                      :projectType="projectType"
                  />
                  <div class="sheet-title d-flex d-lg-none" @click="toggleExpand(false)" v-if="state.expand">
                    <b class="font-base">리워드 선택</b>
                    <span class="font-lg">&times;</span>
                  </div>
                  <Rewards
                      :projectSeq="projectSeq"
                      :projectName="state.project.projectName"
                      :id="`${component.name}Rewards`"
                      :submitBtnId="rewardPaymentSubmitBtnId"
                      :progressOrder="Number(state.project.progressOrder)"
                      :successFailFlag="state.project.successFailFlag"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relatives" v-if="state.loaded && projectType === 'reward'">
        <DetailRelatives :projectSeq="projectSeq" :projectCate="state.project.projectCate" :simulationFlag="state.mockFlag"/>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Stick from "@/components/Stick";
import DetailIntro from "./DetailIntro";
import DetailRewards from "./DetailRewards";
import DetailCommunity from "./DetailCommunity";
import DetailCheers from "./DetailCheers";
import DetailGuide from "./DetailGuide";
import DetailInvestorRelations from "./DetailInvestorRelations";
import DetailInvestors from "./DetailInvestors";
import DetailRelatives from "./DetailRelatives";
import DetailCoop from "./DetailCoop";
import MemberPicture from "@/components/MemberPicture";
import Loading from "@/components/Loading";
import ProjectDetailJoinBtn from "@/components/ProjectDetailJoinBtn";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import track from "@/scripts/track";
import Anchor from "@/components/Anchor";
import redirectCallback from "@/scripts/redirectCallback";
import MakerInfo from "@/components/MakerInfo";
import Rewards from "@/components/Rewards.vue";

function Component(initialize) {
  this.name = "pageProjectDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Rewards,
    MakerInfo,
    Anchor,
    Loading,
    Stick,
    DetailIntro,
    DetailRewards,
    DetailCommunity,
    DetailCheers,
    DetailGuide,
    DetailInvestorRelations,
    DetailInvestors,
    DetailRelatives,
    DetailCoop,
    MemberPicture,
    ProjectDetailJoinBtn,
  },
  props: {
    projectType: String,
  },
  setup(props) {
    const component = new Component(async () => {
      if (router.app.$route.path.includes("/changeHistory") && !store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      state.loaded = false;
      const args = lib.isPreviewPage() ? {preview: "Y"} : undefined;

      const res1 = await http.get(`/api/${props.projectType}/projects/${projectSeq}`, args, {cache: true}).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 401:
            return store.dispatch("moveBack", `/${props.projectType}/list`);

          case 403:
            return store.dispatch("moveBack", `/${props.projectType}/list`);

          case 404:
            return router.replace("/error/404");
        }
      }));

      state.loaded = true;

      if (res1.data.code === "301 MOVED_PERMANENTLY") {
        window.alert("해당 프로젝트가 아직 오픈되지 않아 오픈예정 페이지로 이동합니다.");

        if (res1.data.body) {
          return router.replace(res1.data.body);
        }

        return router.replace("/error/404");
      }

      if (res1?.error) {
        return;
      }

      state.mockFlag = res1.data.body.mockFlag;
      state.project = res1.data.body.project;
      state.newsCount = res1.data.body.newsCount;
      state.qnaCount = res1.data.body.qnaCount;

      if (state.mockFlag === "Y") {
        state.contestInfo = res1.data.body.contestInfo;
      }

      if (state.project.subscribeAmount && typeof state.project.subscribeAmount !== "number") {
        state.project.subscribeAmount = window.Number(state.project.subscribeAmount.replaceAll(",", ""));
      }

      if (state.project.homepage) {
        state.project.homepage = state.project.homepage.replaceAll(" ", "").trim();
      }

      await store.dispatch("setDocumentTitle", state.project.projectName);

      if (props.projectType === "invest") {
        store.state.account.loggedIn && res1.data.body.isExistNotConfirmedChange && store.commit("confirm", {
          message: "본 프로젝트는 투자 판단에 큰 영향을 미칠 수 있는 중요사항이 변경되었습니다. 청약 기간 내에 투자 의사를 재확인하시지 않으실 경우 투자는 자동 취소됩니다. 확인 버튼을 통해 상세 내용 및 투자 의사를 확인해주세요.",
          allow() {
            router.push({path: `/invest/${projectSeq}/changeHistory/${res1.data.body.historySeq}`});
          },
          disallow() {
            router.push({path: `/${props.projectType}/list`});
          }
        });
      } else {
        const args = lib.isPreviewPage() ? {preview: "Y"} : undefined;

        if (state.project.fundingType === "A") {
          // AON의 경우 결제일은 종료일 + 2일
          const investEndDate = lib.getDate(state.project.investEndDate);
          investEndDate.setDate(investEndDate.getDate() + 2);
          state.project.payDate = lib.getDateFormat(investEndDate, "yyyy-MM-dd");
        } else {
          state.project.payDate = state.project.investEndDate;
        }

        state.loaded = false;
        const res2 = await http.get(`/api/reward/projects/${projectSeq}/rewards`, args, {cache: true}).catch(httpError());
        state.loaded = true;

        if (res2?.error) {
          return;
        }

        state.loaded = true;
        state.rewardItems = res2.data.body;
      }

      if (props.projectType === "invest") {
        state.tab.list.push(...[
          {name: "ir", title: "온라인 IR", count: null},
          {name: "news", title: "커뮤니티", count: state.newsCount + state.qnaCount},
          {name: "investors", title: "투자자", count: state.project.subscribeCount},
        ]);
      } else {
        state.tab.list.push(...[
          {name: "intro", title: "프로젝트 소개", count: null},
          {name: "news", title: "커뮤니티", count: state.newsCount + state.qnaCount + state.project.reviewCount},
          {name: "cheer", title: state.mockFlag === "Y" ? "투자자" : "후원자", count: state.project.investorCnt},
          // {name: "reward", title: "리워드 안내", count: null},
        ]);

        if (state.project?.crowdYn === "Y") {
          state.tab.list.push({name: "cooperation", title: "협업 지원", count: null});
        }
      }

      if (Array.isArray(res1.data.body.thumbNailList)) {
        state.project.thumbnails = res1.data.body.thumbNailList;
        // state.project.thumbnails.forEach(e => { e.thumbFilePath = encodeURI(e.thumbFilePath)});
        // console.log(definitions.urls.imgCdn);
        // state.project.thumbnails.forEach(e => { console.log(e.thumbFilePath)})
      }

      // amounts.myContestAvailableAmt = state.contestInfo.investPossibleLimit - state.contestInfo.myContestInvestAmt;
      // amounts.myProjectAvailableAmt = state.contestInfo.projectPossibleLimit - state.contestInfo.myProjectInvestAmt;
      //
      // if (amounts.myContestAvailableAmt >= amounts.myProjectAvailableAmt) {
      //   amounts.myAvailableAmt = amounts.myProjectAvailableAmt;
      // } else {
      //   amounts.myAvailableAmt = amounts.myContestAvailableAmt;
      // }

      let tagIdx = 0;
      state.project.tags = [];

      for (let t of lib.getSplit(state.project.tag, ["\r\n", "\r", "\n", ","])) {
        t && t.trim() && state.project.tags.push(t.replaceAll("#", "").trim());

        if (++tagIdx === 5) {
          break;
        }
      }

      nextTick(() => {
        if (state.project.thumbnails && state.project.thumbnails.length > 1) {
          new window.Swiper(slider1Ref.value, {
            effect: "fade",
            spaceBetween: 10,
            loop: true,
            slidesPerView: state.project.thumbnails ? state.project.thumbnails.length : "auto",
            pagination: {
              el: `#${component.name}ThumbPagination`,
              clickable: true,
              renderBullet: function (index, className) {
                return `<span class="${className}"></span>`; // bullet에 번호 추가
              },
            },
          });
        }

        setTab();
      });

      track.post({
        name: `${props.projectType}ProjectDetail`,
        category: props.projectType === "invest" ? "투자하기" : state.mockFlag === "Y" ? "모의투자" : "후원하기",
        topic: "상세 조회",
        title: state.project.projectName,
      });
    });

    const state = reactive({
      mockFlag: "N",
      contestInfo: {
        contestSeq: "0",
        projectPossibleLimit: 0,
        currencyUnit: "",
        projectStateHideYn: "N",
        investorNameAltText: "",
        investorNameOpenYn: "",
      },
      project: {
        projectCate: "Wait",
        projectCateName: "Wait",
        projectName: "Wait a moment",
        targetAmt: 0,
        expenseAmt: 0,
        subscribeAmount: 0,
        per: "0",
        subscribeRate: "0",
        investorCnt: 0,
        thumbnails: [],
        builderName: "Wait a moment",
        corporateName: "Wait a moment",
        fundType: "",
        fundingType: "Wait a moment",
        endFlag: "",
        displayStateName: "Wait a moment",
        totAmount: 0,
        payDate: "",
        originThumbnailYn: "N",
        progressOrder: 0,
        mainImagePosition: 0,
      },
      climb: false,
      rewardItems: [],
      loaded: false,
      fixed: false,
      newsCount: null,
      qnaCount: null,
      expand: false,
      tab: {
        idx: 0,
        list: [],
      },
    });

    const slider1Ref = ref();
    const slider2Ref = ref();
    const contentRef = ref();
    const contentWrapperRef = ref();
    const detailIntroRef = ref();
    const scrollRef = ref();
    const rewardPaymentSubmitBtnId = `${component.name}PaymentSubmitBtn`;

    const computedCurrencyUnit = computed(() => {
      return state.contestInfo.currencyUnit || "원";
    });

    const computedIsProgressing = computed(() => {
      return Number(state.project.progressOrder) === 1 || lib.isPreviewPage();
    });

    const computedScreenWidthLessThan991 = computed(() => {
      return lib.isMobile() || window.innerWidth <= 991;
    });

    const computedGetHeaderHeight = computed(() => {
      return lib.parseInt(store.state.styles.ground.paddingTop.replace("px", ""));
    });

    const projectSeq = Number(router.app.$route.params.projectSeq);
    const swiperThumbnailSpaceBetween = 5;

    const pushRouterTabQuery = (name) => {
      if (!["intro", "cooperation"].includes(name) && lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      const query = {};

      if (name !== "intro") {
        query.tab = name;
      }

      if (JSON.stringify(query) === JSON.stringify(router.app.$route.query)) {
        return;
      }

      const paths = [props.projectType];
      lib.isPreviewPage() && paths.push("preview");
      paths.push(projectSeq.toString());
      router.push({path: `/${paths.join("/")}`, query});
    };

    const goComment = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      select(0);
      pushRouterTabQuery("intro");
      nextTick(detailIntroRef.value.goComment);
    };

    const submit = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      if (props.projectType === "reward") {
        if (state.mockFlag === "Y") {
          return router.push({path: `/mock/${projectSeq}/order`});
        } else if (!state.rewardItems.length) {
          return store.commit("setSwingMessage", "리워드가 없습니다. 관리자에게 문의해주세요.");
        } else {
          if (computedScreenWidthLessThan991.value) {
            toggleExpand(true);
          } else {
            const $submitBtn = document.getElementById(rewardPaymentSubmitBtnId);

            return window.scrollTo({
              top: window.scrollY + $submitBtn?.getBoundingClientRect().top,
              left: 0,
              behavior: "smooth"
            });
          }
        }
      } else {
        router.push({path: `/invest/${projectSeq}/order`}).then();
      }
    };

    const toggleExpand = (val) => {
      if (!computedScreenWidthLessThan991.value) {
        return;
      }

      if (val !== undefined) {
        state.expand = val;
      } else {
        state.expand = !state.expand;
      }

      if (state.expand) {
        document.body.classList.add("overflow-hidden");
        store.commit("setComponentStyle", {
          to: "climbing",
          key: "display",
          value: "none",
        });
      } else {
        document.body.classList.remove("overflow-hidden");
        store.commit("setComponentStyle", {
          to: "climbing",
          key: "display",
          value: "flex"
        });
      }

      const query = lib.getRenewed(router.app.$route.query);

      if ((query.expand && state.expand) || (!query.expand && !state.expand)) {
        return;
      }

      if (state.expand) {
        query.expand = true;
        router.push({query});
      } else {
        delete query.expand;
        router.replace({query});
      }
    };

    const increaseShareCount = () => {
      http.put(`/api/${props.projectType}/projects/${projectSeq}/share`).then(() => {
        state.project.share = window.Number(state.project.share) + 1;
      });
    };

    const openShareModal = () => {
      const paths = [props.projectType, projectSeq];
      lib.isPreviewPage() && paths.splice(1, 0, "preview");

      store.commit("openModal", {
        name: "Share",
        params: {
          path: `/${paths.join("/")}`,
          title: state.project.projectName,
          desc: state.project.simpleText,
          img: {
            url: store.getters.thumbnailUrl(state.project.thumbnails[0]?.thumbFilePath, {share: true}),
            width: definitions.thumbnail.width,
            height: definitions.thumbnail.height
          }
        },
        callback: `${component.name}.increaseShareCount`
      });
    };

    const openProjectDetailInfoForAdmin = () => {
      store.commit("openModal", {
        name: "ProjectDetailForAdmin",
        params: {
          projectType: props.projectType,
          projectSeq,
        }
      });
    };

    const select = (idx) => {
      if (!contentRef.value) {
        return;
      } else if (lib.isPreviewPage() && !["intro", "cooperation", "ir"].includes(state.tab.list[idx].name)) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      state.tab.idx = idx;
      // const contentOffsetTop = contentRef.value.offsetTop + parseFloat(window.getComputedStyle(contentRef.value).paddingTop);
      // const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //
      // if (scrollTo || curScrollTop > contentOffsetTop) {
      //   nextTick(() => {
      //     window.scrollTo({
      //       top: contentOffsetTop,
      //       left: 0,
      //       behavior: "smooth"
      //     });
      //   });
      // }
    };

    const toggleInterest = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      const args = {
        projectSeq,
        projectType: props.projectType,
        projectName: state.project.projectName
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleProjectInterest", {...args, refresh: true});
      }

      redirectCallback.run("toggleProjectInterest", {
        ...args,
        onSuccess(include, has) {
          if (!include) {
            state.project.interestCnt = Number(state.project.interestCnt) - 1;
          } else if (!has) {
            state.project.interestCnt = Number(state.project.interestCnt) + 1;
          }
        }
      });
    };

    const openProfileModal = () => {
      if (!state.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "Profile",
        params: {
          memberName: props.projectType === "invest" ? state.project.corporateName : state.project.builderName,
          memberSeq: props.projectType === "invest" ? state.project.memberSeq : state.project.openId,
          memberIntro: props.projectType === "invest" ? state.project.introduction : state.project.builderIntroduction,
          projectType: props.projectType,
          projectSeq: projectSeq,
          email: props.projectType === "invest" ? state.project.managerEmail : state.project.builderEmail,
          homepage: props.projectType === "invest" ? state.project.homepage : "",
          phoneNum: props.projectType === "invest" ? state.project.businessTel : "",
          imgPath: state.project.builderImageUrl,
        }
      });
    };

    const onScroll = () => {
      if (!contentWrapperRef.value) {
        return;
      }

      const top = contentWrapperRef.value.getBoundingClientRect().top - lib.getHeaderHeight();
      state.fixed = (top <= 0 && !lib.isMobile());
    };

    const setTab = () => {
      const tab = router.app.$route.query.tab || router.app.$route.params.tab;

      switch (tab) {
        case "news":
        case "qna":
        case "review":
          select(1);
          break;

        case "cheer":
        case "investors":
          select(2);
          break;

        case "cooperation":
          select(3);
          break;

        default:
          select(0);
          break;
      }
    };

    const investSubmit = (param) => {
      switch (param) {
        case "confirm":
          return store.commit("confirm", {
            message: "해당 프로젝트는 청약금액을 달성하여 종료 후 배정을 못 받을 수 있습니다. 진행하시겠습니까?",
            allow: submit,
          });

        case "finished":
          return store.commit("setSwingMessage", "마감된 프로젝트입니다. 감사합니다.");

        case "preparing":
          return store.commit("setSwingMessage", "아직 펀딩이 시작되지 않았습니다.");
      }
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      setTab();

      (!router.app.$route.query.expand || router.app.$route.query.expand !== "true") && toggleExpand(false);

      if (!state.fixed) {
        return;
      }

      window.scrollTo(0, 0);

      setTimeout(() => {
        const wrapperPaddingTop = window.Number(lib.getComputedStyle(contentWrapperRef.value, "paddingTop")?.replace("px", ""));
        const gap = wrapperPaddingTop || 60;
        window.scrollTo(0, scrollRef.value.getBoundingClientRect().top - lib.getHeaderHeight() - gap);
      });
    });

    onMounted(() => {
      store.commit("addComponentClass", {to: "ground", value: "no-padding-top"});
      store.commit("addComponentClass", {to: "header", value: "no-fixed"});
      store.commit("addComponentClass", {to: "footer", value: "margin-bottom"});

      store.commit("addListener", [component.name, "onScroll", () => {
        state.climb = lib.isClimbable();
        onScroll();
      }]);

      computedScreenWidthLessThan991.value && store.commit("setComponentStyle", {
        to: "climbing",
        key: "bottom",
        value: 88 + "px"
      });

    });

    onUnmounted(() => {
      store.commit("removeComponentClass", {to: "ground", value: "no-padding-top"});
      store.commit("removeComponentClass", {to: "header", value: "no-fixed"});
      store.commit("removeComponentClass", {to: "footer", value: "margin-bottom"});
      document.body.classList.remove("overflow-hidden");

      if (computedScreenWidthLessThan991.value) {
        store.commit("setComponentStyle", {
          to: "climbing",
          key: "bottom",
          value: 8 + "px"
        });

        store.commit("setComponentStyle", {
          to: "climbing",
          key: "display",
          value: "flex"
        });
      }
    });

    return {
      component,
      state,
      slider1Ref,
      slider2Ref,
      contentRef,
      contentWrapperRef,
      detailIntroRef,
      scrollRef,
      projectSeq,
      rewardPaymentSubmitBtnId,
      swiperThumbnailSpaceBetween,
      computedCurrencyUnit,
      computedIsProgressing,
      computedGetHeaderHeight,
      toggleExpand,
      increaseShareCount,
      openShareModal,
      openProjectDetailInfoForAdmin,
      select,
      setTab,
      pushRouterTabQuery,
      goComment,
      submit,
      toggleInterest,
      openProfileModal,
      investSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail {
  > .wrapper {
    > .summary {
      padding: $px64 0 $px48;

      > .container {
        > .info {
          display: flex;
          justify-content: space-between;
          gap: $px32;

          .left {
            max-width: $px600;
            width: 100%;
            flex-shrink: 0;

            > .wrapper {
              position: relative;
              overflow: hidden;
              border-radius: $thumbBorderRadius;
              padding-top: $ratio43Percent;
              background-color: #fff;

              .video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                iframe {
                  width: 100%;
                  height: 100%;
                }
              }

              .slider {
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .swiper-wrapper {
                  .swiper-slide {
                    height: 100%;

                    .img {
                      width: 100%;
                      height: 100%;
                      background-size: auto 100%;
                      background-position: center center;
                    }

                    &:last-child {
                      margin-right: 0 !important;
                    }
                  }
                }

                .pagination::v-deep {
                  background-color: rgba(#000, 0.2);
                  border-radius: $px100;
                  gap: $px5;
                  justify-content: center;
                  align-items: center;
                  padding: $px4 $px8;
                  position: absolute;
                  left: 50%;
                  bottom: $px16;
                  transform: translateX(-50%);
                  width: auto;
                  z-index: 1;

                  > span {
                    margin: 0;
                    width: $px6;
                    height: $px6;
                    background: #fff;
                    opacity: 1;

                    &.swiper-pagination-bullet-active {
                      width: $px10;
                      height: $px10;
                      background: $colorPoint;
                    }
                  }
                }
              }
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: $px20;
            flex-grow: 1;

            .top {
              display: flex;
              flex-direction: column;
              gap: $px14;

              .project-name {
                font-size: $px24;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: $px4;
              }

              .category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: $px16;

                > a {
                  white-space: nowrap;
                }

                .types {
                  > span {
                    border-radius: $px12;
                    border: $px1 solid $colorBorder;
                    display: inline-block;
                    font-size: $px14;
                    padding: $px10 $px14;
                    line-height: 1;

                    &:not(:last-child) {
                      margin-right: $px4;
                    }
                  }
                }

                .tags {
                  > ul {
                    white-space: nowrap;
                    overflow: auto;
                    max-width: 100%;
                    vertical-align: middle;
                    text-align: right;

                    li {
                      display: inline-block;
                      background: #f9f9f9;
                      border-radius: $px12;
                      color: $colorTextSecondary;
                      font-size: 0;
                      white-space: nowrap;

                      > a, > b {
                        display: inline-block;
                        padding: $px10 $px14;
                      }

                      &:not(:last-child) {
                        margin-right: $px4;
                      }
                    }
                  }
                }
              }

              .callout {
                .amount {
                  margin-bottom: $px8;

                  > div > b {
                    font-size: $px22;
                    font-weight: 700;
                  }
                }

                .graph {
                  background-color: #e1e1e1;
                  border-radius: $px2;
                  flex-shrink: 0;
                  height: $px3;
                  position: relative;
                  width: 100%;
                  margin-bottom: $px8;

                  > div {
                    height: 100%;
                    min-width: 2%;
                  }
                }

                .cheer {
                  > .img {
                    width: $px16;
                    height: $px16;
                  }
                }
              }

              .organize {
                display: flex;
                flex-direction: column;
                gap: $px10;
                word-break: keep-all;
                font-size: $px14;

                .d-flex {
                  .subject {
                    width: $px130;
                    flex-shrink: 0;

                    > img {
                      margin-right: $px4;
                    }
                  }

                  .dropdown {
                    i {
                      margin-left: $px5;
                    }

                    .dropdown-menu {
                      padding: $px16;
                      font-size: $px14;
                      min-width: $px230;
                    }
                  }
                }

                .more {
                  text-align: center;
                  font-size: $px14;

                  .child {
                    text-align: left;

                    > .wrapper {
                      display: flex;
                      flex-direction: column;
                      gap: $px10;
                    }
                  }

                  > a {
                    display: inline-block;
                    padding: $px8 $px16;

                    i {
                      vertical-align: middle;
                    }

                    &:before {
                      content: "접기";
                      padding-right: $px4;
                    }

                    &.collapsed {
                      &:before {
                        content: "더보기";
                      }

                      i {
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
              }
            }

            .actions {
              height: $px72;
              display: flex;
              gap: $px16;

              .submit {
                width: 66%;
              }

              .btn {
                border: $px1 solid #eee;
                border-radius: $px12;
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: $px4;
                flex-grow: 1;

                > .img {
                  height: $px20;
                  width: $px20;
                  position: relative;
                }
              }
            }
          }
        }

        > .profile {
          margin-top: $px24;
        }

        > .alert {
          padding: $px20;
          border-radius: $px20;
          margin-top: $px32;
          margin-bottom: 0;
          background: $colorBackground;
        }
      }
    }

    > .content {
      padding-bottom: $px32;
      min-height: 550px;

      > .wrapper {
        position: relative;

        > .tabs {
          background: $colorBackground;
          display: block;
          width: 100%;
          z-index: 2;

          > .container > .wrapper {
            ul {
              border: 0;
              border-radius: $px16;
              display: block;
              padding: $px8 0;
              overflow: auto;
              white-space: nowrap;

              li {
                display: inline-block;
                text-align: center;
                width: $px110;

                a {
                  background: none;
                  box-shadow: none;
                  border-radius: $px10;
                  padding: $px16;
                  border: 0;
                  margin: 0;
                  font-size: $px14;
                  line-height: 1;

                  &.active {
                    color: $colorPoint;
                    background: #fff;

                    > span {
                      > .title {
                        font-weight: 500;
                      }

                      > .count {
                        background: $colorPoint;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .article {
          > .wrapper {
            &.intro {
              .reservation {
                border: $px1 solid #eee;
                background: #fff;
                padding: $px10;
                margin-top: $px20;
              }
            }
          }
        }

        .right {
          > .wrapper {
            position: relative;
            height: 100%;

            > .title {
              margin-bottom: $px16;
              font-size: $px20;

              span {
                font-weight: 600;
              }
            }

            > .profile {
              margin-top: $px32;
            }

            .rewards::v-deep {
              margin-top: $px20;
              position: sticky;
              left: 0;
              top: $px20;
              overflow: hidden;
              height: calc(100vh - ($px20 * 2)); //여백

              > .wrapper {
                max-height: 100%;

                > ul {
                  overflow: auto;
                }
              }
            }
          }
        }
      }
    }

    .relatives {
      padding-bottom: $px32;
    }
  }

  @media (max-width: 1199px) {
    > .wrapper > .summary .left > .wrapper .sliders {
      height: auto;
    }
  }

  @media (min-width: 992px) {
    > .wrapper > .summary > .container > .info .right .top {
      .category {
        min-height: $px38;

        .tags > ul {
          width: $px370;
        }
      }

      .project-name {
        min-height: $px57;
      }
    }
  }

  @media (max-width: 991px) {
    > .wrapper {
      > .summary {
        padding: $px32 0;

        > .container {
          > .info {
            align-items: center;
            flex-direction: column;
            gap: $px24;

            .right {
              width: 100%;

              .top {
                gap: $px24;

                .category {
                  .tags {
                    width: 100%;
                  }
                }

                .organize {
                  .d-flex {
                    .subject {
                      width: $px130;
                    }
                  }
                }
              }

              .actions {
                background: #fff;
                box-shadow: $boxShadow;
                border-radius: $px24 $px24 0 0;
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                margin: 0;
                height: $px80;
                z-index: 5;
                padding: $px10 $px20;

                .btn {
                  border: 0;

                  > .img {
                    width: $px24;
                    height: $px24;
                    flex-shrink: 0;
                  }
                }
              }
            }
          }
        }
      }

      > .content {
        padding-bottom: $px40;

        > .wrapper {
          > .tabs {
            > .container > .wrapper {
              margin: 0 -15px;

              ul {
                padding-left: 15px;
                padding-right: 15px;
              }
            }

            //&.fixed {
            //  top: 0;
            //
            //  > .container {
            //    padding-right: $px10;
            //    padding-left: $px10;
            //
            //    > .wrapper {
            //      padding-top: $px3;
            //      padding-bottom: $px3;
            //
            //      > .project-detail-join-btn {
            //        display: none;
            //      }
            //    }
            //  }
            //}
          }

          .article {
            margin-bottom: 0;
          }

          .right {
            .wrapper {
              position: fixed;
              left: 0;
              bottom: 0;
              background: #fff;
              box-shadow: $boxShadow;
              border-radius: $px24 $px24 0 0;
              top: initial !important;
              transform: translateY(100%);
              transition: 0.25s;
              max-height: calc(100vh - $px200);
              width: 100%;
              z-index: 20; //헤더 zIndex와 같음
              // iOS 설정
              will-change: transform;
              backface-visibility: hidden;
              height: auto;

              .rewards {
                margin: 0;
              }

              &.expand {
                transform: translateY(0);
                display: flex;
                flex-direction: column;

                .sheet-title {
                  font-size: $px18;
                  line-height: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: $px20;
                  z-index: 1;
                }

                .rewards::v-deep {
                  position: initial;
                  display: flex;
                  flex-direction: column;

                  > .wrapper {
                    flex-grow: 1;
                    min-height: 0; //ios height 이슈

                    > ul {
                      padding: 0 $px20 $px20;
                    }

                    .sheet {
                      display: flex;
                      flex-direction: column-reverse;
                      justify-content: space-between;

                      > .content {
                        padding: 0 $px20 $px20;
                        margin: 0;
                      }

                      > .actions {
                        box-shadow: $boxShadow;
                        padding: $px10 $px20;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bg-black {
          background: rgba(0, 0, 0, 0.2);
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 20; //헤더 zIndex와 같음
        }
      }
    }

    &:not([data-tab=intro]) {
      > .wrapper {
        .relatives {
          display: none;
        }
      }
    }
  }

  &.skeleton {
    > .wrapper {
      > .summary > .container {
        .info {
          .left {
            > .wrapper {
              .slider {
                border-color: transparent;

                .swiper-wrapper > .swiper-slide {
                  @include skeleton;

                  > img {
                    visibility: hidden;
                  }
                }

                .pagination {
                  display: none;
                }
              }
            }
          }

          .right {
            .top {
              .project-name {
                @include skeleton;
              }

              .category {
                > a, .tags > ul li {
                  @include skeleton;
                }
              }

              .callout {
                > span, .amount div, .amount b, .graph div, .cheer b, .cheer span {
                  @include skeleton;
                }
              }

              .organize {
                > span {
                  @include skeleton;
                }

                > .d-flex {
                  @include skeleton;

                  .subject, span, .badge, i, b {
                    @include skeleton;
                  }

                  .subject img {
                    display: none;
                  }
                }

                .more a {
                  @include skeleton;
                }
              }
            }

            .actions::v-deep {
              .btn {
                @include skeleton;

                .img {
                  display: none;
                }
              }
            }
          }
        }

        .alert {
          @include skeleton;

          a {
            color: inherit !important;
          }
        }
      }
    }
  }
}
</style>