<template>
  <div class="detail-investors" :class="{skeleton: !state.loaded}" ref="wrapRef">
    <div v-if="state.investors && state.investors.length">
      <ul class="tight">
        <li v-for="(c, idx) in state.investors" :key="idx">
          <div class="profile">
            <MemberPicture :memberSeq="c.memberSeq" size="35"/>
            <span class="name font-sm">{{ fundType === "02" ? "투자자" + (idx + 1) : c.memberName }}</span>
            <span class="color-secondary font-xs">{{ c.createDate }}</span>
          </div>
          <div class="badge badge-point-soft" v-if="c.subscrAmount">
            <span>₩{{ $lib.getNumberFormat(c.subscrAmount) }}원 투자</span>
          </div>
        </li>
      </ul>
      <div class="page">
        <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
      </div>
    </div>
    <div v-else class="part">
      <NoData/>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import MemberPicture from "@/components/MemberPicture.vue";
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";
import mixin from "@/scripts/mixin";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageProjectDetailInvestors";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, MemberPicture, Pagination, Loading},
  props: {
    projectSeq: Number,
    projectType: String,
    fundType: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.investors.push({
          createDate: "0000-00-00 00:00",
          subscrAmount: 1000,
          memberName: "Wait a moment"
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      investors: [],
      pagination: {},
    });

    const wrapRef = ref();

    const load = (num) => {
      const args = {
        pageIndex: num ? num : 1,
        pageSize: 5,
        pageUnit: 5,
      };

      state.loaded = false;
      http.get(`/api/invest/projects/${props.projectSeq}/coop`, args).then(({data}) => {
        state.loaded = true;
        state.investors = data.body;
        state.pagination = data.body.paginationInfo;
      });

      if (num) {
        nextTick(() => {
          const rect = wrapRef.value.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          window.scrollTo({
            top: rect.top + scrollTop - 78,
            left: 0,
            behavior: "smooth"
          });
        });
      }
    };

    return {component, state, wrapRef, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-investors {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: $px24;
    margin-top: $px20;

    li {
      width: calc(50% - $px12);
      display: flex;
      justify-content: space-between;
      align-items: center;


      .profile {
        display: flex;
        align-items: center;
        gap: $px8;

        .name {
          font-weight: 500;
        }
      }

      .badge {
        padding: $px10 $px20;
        border-radius: $px100;
      }
    }
  }

  .page {
    margin-top: $px20;
  }

  &.skeleton {
    ul li {
      .profile {
        .img, span {
          @include skeleton;
        }
      }

      .badge {
        @include skeleton;
      }
    }
  }

  @media (max-width: 767px) {
    ul li {
      width: 100%;
    }
  }
}
</style>