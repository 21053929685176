<template>
  <div class="detail-intro-comments" :class="{skeleton: !state.loaded }" :id="`${component.name}IntroCheerComment`">
    <div class="comment">
      <div class="form">
        <div class="title">응원 한마디</div>
        <div class="input">
          <div class="textarea">
          <textarea class="form-control" :placeholder="$lib.isPreviewPage() ? '미리보기에서는 지원하지 않습니다.' : $store.state.account.loggedIn ? '응원 한마디를 남겨주세요.' : '클릭하여 로그인하세요.'"
                    :readonly="!$store.state.account.loggedIn" :disabled="$lib.isPreviewPage()" @focus="checkLogin()" ref="commentTextareaRef"></textarea>
          </div>
          <button class="btn btn-secondary font-sm" @click="post()" v-if="$store.state.account.loggedIn && !$lib.isPreviewPage()">등록</button>
        </div>
      </div>
      <div class="list" v-if="Array.isArray(state.comments) && state.comments.length">
        <ul class="tight">
          <li v-for="(c, idx) in state.comments" :key="`${c.createDate}${c.memberSeq}${idx}`">
            <Comment
                :content="c.content"
                :createDate="c.createDate"
                :depth="c.depth"
                :edit="edit"
                :idx="idx"
                :memberSeq="c.memberSeq"
                :memberName="c.memberName"
                :memberAuth="c.memberAuth"
                :openId="openId"
                page="introduce-cheer"
                :remove="remove"
                :reply="reply"
                :skeleton="!state.loaded"
            />
          </li>
        </ul>
        <div class="page" v-if="state.paginationInfo.lastPageNo > 1">
          <Pagination :info="state.paginationInfo" :change="load"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import MemberPicture from "@/components/MemberPicture";
import Comment from "@/components/Comment.vue";

function Component(initialize) {
  this.name = "pageProjectDetailComments";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, MemberPicture, Pagination},
  props: {
    projectType: String,
    projectSeq: Number,
    openId: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.comments.push({
          content: "Please wait a moment. Please wait a moment.",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          children: []
        });
      }

      load(null, true);
    });

    const state = reactive({
      pageIndex: 1,
      loaded: false,
      comments: [],
      paginationInfo: {},
    });

    const commentRef = ref();
    const commentTextareaRef = ref();

    const checkLogin = () => {
      !store.state.account.loggedIn && store.dispatch("goLoginPage");
    };

    const load = (pageIndex, preventScroll) => {
      state.pageIndex = pageIndex ? pageIndex : 1;
      state.loaded = false;

      const args = {
        pageIndex: state.pageIndex,
        pageUnit: 5,
        pageSize: 5,
      };

      !preventScroll && window.scroll({
        top: window.scrollY + document.getElementById(`${component.name}IntroCheerComment`)?.getBoundingClientRect()?.y,
        left: 0,
      });

      http.get(`/api/reward/projects/${props.projectSeq}/intro-comments`, args).then(({data}) => {
        state.loaded = true;
        state.comments = data.body.commentList;
        state.paginationInfo = data.body.paginationInfo;
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const post = () => {
      const content = commentTextareaRef.value.value;

      if (!content?.trim()) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        commentTextareaRef.value.focus();
        return;
      }

      const args = {
        content: content,
        createId: store.state.account.memberSeq
      };

      http.post(`/api/reward/projects/${props.projectSeq}/intro-comments`, args).then(() => {
        load(null, true);
        commentTextareaRef.value.value = "";
      });
    };

    const patch = ({commentSeq, content, updateId}) => {
      http.patch(`/api/reward/projects/${props.projectSeq}/intro-comments/${commentSeq}`, {content, updateId}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        load(state.pageIndex, true);
      }).catch(httpError());
    };

    const edit = (idx) => {
      const comment = state.comments[idx];
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content,
          maxByte: 500,
        },
        callback: `${component.name}.patch`,
      });
    };

    const remove = (idx) => {
      const commentSeq = state.comments[idx].commentSeq;

      if (confirm("삭제하시겠습니까?")) {
        const args = {
          updateId: store.state.account.memberSeq
        };

        http.delete(`/api/reward/projects/${props.projectSeq}/intro-comments/${commentSeq}`, args).then(() => {
          store.commit("setSwingMessage", "삭제하였습니다.");
          load(state.pageIndex, true);
        });
      }
    };

    const reply = (idx) => {
      const comment = state.comments[idx];
      const parentComment = state.comments.find(c => c.groupNum?.toString() === comment.groupNum?.toString() && c.depth?.toString() === "0");

      const params = {
        groupNum: comment.groupNum,
      };

      parentComment.createId && (params.parentWriterSeq = parentComment.createId);


      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          ...params,
        },
        callback: `${component.name}.submitReply`
      });
    };

    const submitReply = (params) => {
      const args = {
        content: params.content,
        groupNum: params.groupNum,
        parentWriterSeq: params.parentWriterSeq,
      };

      http.post(`/api/maker/project/${props.projectType}/${props.projectSeq}/introduce-cheer/reply`, args).then(() => {
        store.commit("setSwingMessage", "답변을 등록하였습니다.");
        load(state.pageIndex, true);
      });
    };

    return {
      component
      , state
      , commentRef
      , commentTextareaRef
      , checkLogin
      , post
      , patch
      , edit
      , remove
      , reply
      , submitReply
      , load
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-intro-comments {
}
</style>