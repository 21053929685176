<template>
  <div class="detail-community">
    <ul class="categories tight">
      <template v-for="(t, idx) in state.tab.list">
        <li :class="{ active: t.name === $route.query.tab }" :key="idx" v-if="t.visible">
          <router-link :to="`?tab=${t.name}`" class="font-sm no-underline">
            <span class="text">{{ t.title }}</span>
            <b class="color-point font-xs pl-1" v-if="t.count > 0">{{ t.count }}</b>
          </router-link>
        </li>
      </template>
    </ul>
    <DetailCommunityNews :projectType="projectType" :projectSeq="projectSeq" :openId="openId" v-if="$route.query.tab === 'news'"/>
    <DetailCommunityQna :projectType="projectType" :projectSeq="projectSeq" :openId="openId" v-if="$route.query.tab === 'qna'"/>
    <DetailCommunityReview :projectType="projectType" :projectSeq="projectSeq" :openId="openId" :investEndDate="investEndDate" :investRewards="investRewards" v-if="$route.query.tab === 'review'"/>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import router from "@/scripts/router";
import DetailCommunityNews from "./DetailCommunityNews.vue";
import DetailCommunityQna from "./DetailCommunityQna.vue";
import mixin from "@/scripts/mixin";
import DetailCommunityReview from "@/pages/project/DetailCommunityReview";

function Component(initialize) {
  this.name = "pageProjectDetailCommunity";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {DetailCommunityReview, DetailCommunityNews, DetailCommunityQna},
  props: {
    projectSeq: Number,
    projectType: String,
    investEndDate: String,
    newsCount: Number,
    qnaCount: Number,
    reviewCount: Number,
    openId: String,
    investRewards: Array,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      tab: {
        idx: 0,
        list: [
          {name: "news", title: "최근 소식", count: props.newsCount, visible: true},
          {name: "qna", title: "Q&A", count: props.qnaCount, visible: true},
          {name: "review", title: "프로젝트 후기", count: props.reviewCount, visible: props.projectType === "reward"}
        ],
      },
    });

    const select = (tab) => {
      if (tab === "news") {
        return state.tab.idx = 0;
      } else if (tab === "qna") {
        return state.tab.idx = 1;
      } else {
        state.tab.idx = 2;
      }
    };

    const setTab = () => {
      const tab = router.app.$route.query.tab || router.app.$route.params.tab;
      select(tab);
    };

    setTab();

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      setTab();
    });

    return {component, state, select, setTab};
  },
})
;
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community {
  padding-top: $px16;

  .categories {
    margin-bottom: $px32;

    li {
      border-bottom: $px2 solid #f2f2f2;
      color: $colorSecondary;
      display: inline-block;
      margin-right: $px8;
      padding: $px12 $px16;
      font-size: 0;

      a {
        line-height: 1;

        span {
          vertical-align: middle;
        }
      }

      &.active {
        border-bottom-color: $colorPoint;
        color: inherit;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 991px) {
    padding-top: $px10;

    .categories {
      margin-bottom: $px15;
    }
  }
}
</style>