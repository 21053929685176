import { render, staticRenderFns } from "./DetailCommunityQna.vue?vue&type=template&id=1f5e2574&scoped=true&"
import script from "./DetailCommunityQna.vue?vue&type=script&lang=js&"
export * from "./DetailCommunityQna.vue?vue&type=script&lang=js&"
import style0 from "./DetailCommunityQna.vue?vue&type=style&index=0&id=1f5e2574&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5e2574",
  null
  
)

export default component.exports