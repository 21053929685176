<template>
  <div class="rewards">
    <template v-if="state.items.length">
      <div class="wrapper" :class="{swiped : state.swiped}">
        <ul class="tight list thin-scrollbar">
          <li :data-reward-seq="item.rewardSeq" v-for="item in state.items" :key="item.rewardSeq" @click="addItem(item)" :class="{'sold-out' : isRewardSoldOut(item.rewardQty, item.extraCount), 'disable': !computedAvailAble}">
            <div class="wrapper" :class="{'selected' : state.selected.seq === item.rewardSeq}">
              <div class="main">
                <div class="count color-point font-sm">
                  <span class="img align-middle mr-1" style="background-image: url(/assets/ico/common.smile.point.svg)"></span>
                  <b class="align-middle">{{ $lib.getNumberFormat(item.joinCount) }}명 후원</b>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <span class="bold font-md" v-if="item.rewardSeq">{{ $lib.getNumberFormat(item.rewardAmt) }}원+</span>
                  <span class="bold font-md" v-else>1,000원+</span>
                  <template v-if="item.rewardQty > 0">
                    <span class="badge badge-danger-soft" v-if="item.rewardQty > 0 && item.extraCount > 0">{{ $lib.getNumberFormat(item.extraCount) }}개 남음</span>
                    <span class="badge badge-secondary-soft" v-else>선착순 마감</span>
                  </template>
                </div>
              </div>
              <div class="title">
                <span class="font-sm" v-html="item.title"></span>
                <router-link :to="`/reward/${$route.params.projectSeq}/order`" class="btn btn-point-soft font-sm" v-if="!item.rewardSeq">
                  <span>후원하기</span>
                </router-link>
              </div>
              <div class="check font-sm" v-if="item.rewardExpectText">
                <div class="subject">
                  <span class="img" style="background-image: url(/assets/ico/common.check.svg)"></span>
                  <span>배송일</span>
                </div>
                <span>{{ item.rewardExpectText }}</span>
              </div>
              <div class="check font-sm" v-if="item.rewardSelFlag === 'Y' && item.rewardOptionItems.length">
                <div class="subject">
                  <span class="img" style="background-image: url(/assets/ico/common.check.svg)"></span>
                  <span>옵션</span>
                </div>
                <ul class="tight">
                  <li class="font-sm" v-for="option in item.rewardOptionItems" :key="`rewardsOptions${option.rewardOptionSeq}`">
                    <span>{{ option.rewardOptionText }}</span>
                  </li>
                </ul>
              </div>
              <div class="actions" v-if="(item.rewardQty === 0 || item.extraCount > 0) && state.selected.seq === item.rewardSeq && item.rewardSelFlag === 'Y'">
                <select class="form-control border-focus-point font-sm" v-model="item.optionText" @click.stop @change.stop="addItem(item, true);" v-if="item.rewardOptionItems.length">
                  <option value="">{{ item.rewardSelText || "옵션을 선택해주세요." }}</option>
                  <!-- option.rewardOptionQty 값이 0이면 수량 제한이 없는 것임 -->
                  <template v-for="(option, idx) in item.rewardOptionItems">
                    <option :value="option.rewardOptionText" :key="idx" :disabled="$lib.parseInt(option.rewardOptionQty) !== 0 && $lib.parseInt(option.extraCount) <= 0" v-if="option.hideFlag !== 'Y'">
                      <span>{{ option.rewardOptionText }}</span>
                      <span v-if="option.rewardOptionQty !== '0'"> (재고: {{ getOptionExtraCount(option) }})</span>
                    </option>
                  </template>
                </select>
                <div class="textarea" v-else>
                  <textarea :data-reward-seq="item.rewardSeq" type="text" class="form-control border-focus-point font-sm no-scrollbar" :placeholder="item.rewardSelText || '리워드 정보 입력'" @click.stop v-model="item.optionText"></textarea>
                  <button class="btn btn-point-soft font-sm" @click.stop="addItem(item, true)"><span>선택</span></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="sheet">
          <div class="actions">
            <ProjectDetailJoinBtn
                projectType="reward"
                mockFlag="N"
                componentNameSuffix="detailRewards"
                :loaded="true"
                :progressOrder="Number(progressOrder)"
                :paymentAmount="computedAmount"
                :submit="submit"
                :successFailFlag="successFailFlag"
                :submitBtnId="submitBtnId"
            />
            <button class="btn btn-light" v-if="state.swiped" @click="toggleSwipe(false)"><span>리워드 추가 선택</span></button>
          </div>
          <div class="content">
            <div class="top d-flex justify-content-between align-items-center mb-3">
              <span>{{ $lib.getNumberFormat(computedTotalCount) }}개</span>
              <div>
                <span>총 </span>
                <b class="color-point">{{ $lib.getNumberFormat(computedAmount) }}원</b>
              </div>
            </div>
            <ul class="tight thin-scrollbar" v-if="state.selected.items.length">
              <li v-for="(i,idx) in state.selected.items">
                <div class="title">
                  <b v-html="i.title"></b>
                  <span class="img" style="background-image: url(/assets/ico/common.close.svg)" title="삭제" @click="removeItem(idx)"></span>
                </div>
                <span class="font-sm color-secondary">{{ i.rewardOptionText || i.optionText }}</span>
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <div class="switch" role="group" aria-label="수량">
                    <button type="button" class="btn btn-point-soft" @click="increase(i, -1)" title="감소" :disabled="i.count === 1">-</button>
                    <span>{{ i.count }}</span>
                    <button type="button" class="btn btn-point-soft" @click="increase(i, 1)" title="증가">+</button>
                  </div>
                  <b>{{ $lib.getNumberFormat(i.rewardAmt * i.count) }}원</b>
                </div>
              </li>
            </ul>
            <div class="font-sm pb-3 text-center w-100 color-secondary" v-else>
              <span>리워드를 선택해주세요</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import track from "@/scripts/track";
import lib from "../scripts/lib";
import router from "../scripts/router";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import ProjectDetailJoinBtn from "@/components/ProjectDetailJoinBtn.vue";

function Component(initialize) {
  this.name = "componentRewards";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ProjectDetailJoinBtn},
  mixins: [mixin],
  props: {
    projectSeq: Number,
    projectName: String,
    progressOrder: Number,
    successFailFlag: String,
    submitBtnId: String,
  },
  setup(props) {
    const component = new Component(() => {
      http.get(`/api/reward/projects/${props.projectSeq}/rewards`, undefined, {cache: true}).then(({data}) => {
        for (const item of data.body) {
          state.items.push({
            rewardSeq: item.rewardSeq,
            joinCount: item.joinCount,
            rewardQty: lib.parseInt(item.rewardQty),
            extraCount: lib.parseInt(item.extraCount),
            rewardAmt: item.rewardAmt,
            title: item.title,
            optionText: item.optionText || "",
            rewardExpectText: item.rewardExpectText,
            rewardSelFlag: item.rewardSelFlag,
            rewardSelText: item.rewardSelText,
            rewardOptionItems: item.rewardOptionItems,
            rewardOptionText: item.rewardOptionText,
            rewardOptionQty: item.rewardOptionQty,
            hideFlag: item.hideFlag,
            count: 1,
          });
        }

        if (props.rewardSeq) {
          addItem(state.items.find(i => i.rewardSeq.toString() === props.rewardSeq.toString()));

          nextTick(() => focusItemOption(props.rewardSeq));
        }
      }).catch(httpError());

      track.post({
        name: `rewardProjectRewards`,
        category: "후원하기",
        topic: "리워드 선택 목록",
        title: props?.projectName,
        type: "page",
      });
    });

    const state = reactive({
      items: [],
      swiped: false,
      selected: {
        items: [],
        seq: "",
      },
    });

    const computedAvailAble = computed(() => {
      return props.progressOrder === 1 || lib.isPreviewPage();
    });

    const computedAmount = computed(() => {
      let output = 0;

      for (const item of state.selected.items) {
        const count = item.count || 0;
        output += item.rewardAmt * count;
      }

      return output;
    });

    const computedTotalCount = computed(() => {
      return state.selected.items.reduce((pre, cur) => pre += cur.count, 0);
    });

    const getOptionExtraCount = (option) => {
      return lib.parseInt(option.extraCount) <= 0 ? "0" : option.extraCount;
    };

    const getCount = (item) => {
      return item.count || 0;
    };

    const getMaxCount = (item) => {
      if (item.rewardQty === 0) {
        return 500;
      }

      return item.extraCount;
    };

    const focusItemOption = (rewardSeq) => {
      const $elem = document.querySelector(`li[data-reward-seq="${rewardSeq}"]`);

      if ($elem) {
        const $parent = $elem.closest("ul.list");
        lib.scrollTo($parent, $elem);
      }
    };

    const increase = (item, value) => {
      if (item.count === 1 && value === -1) {
        return;
      }

      if (value === 1 && !calcQuantity(item)) {
        return;
      }

      item.count += value;
      item.count > 0 && item.rewardSelFlag === "Y" && nextTick(() => {
        focusItemOption(item.rewardSeq);
      });
    };

    const toggleSwipe = (val) => {
      if (val !== undefined) {
        return state.swiped = val;
      }

      state.swiped = !state.swiped;
    };

    const calcQuantity = (item, step) => {
      const selectedReward = state.selected.items.filter(i => i.rewardSeq === item.rewardSeq);

      if ((selectedReward.length && item.rewardQty > 0) && selectedReward.reduce((pre, cur) => pre + cur.count, 0) >= item.extraCount) {
        if (step === "list") {
          state.selected.seq = "";
          item.optionText = "";
        }

        window.alert("수량이 리워드 재고를 초과했습니다.");
        return false;
      }

      if (item.rewardOptionItems?.length > 0) {
        const matchingReward = state.selected.items.find(i => i.rewardSeq === item.rewardSeq && i.optionText === item.optionText);
        const rewardOption = item.rewardOptionItems.find(o => o.rewardOptionText === item.optionText);

        if ((item.rewardQty && matchingReward?.count >= item.extraCount) || (lib.parseInt(rewardOption.rewardOptionQty) && matchingReward?.count >= lib.parseInt(rewardOption.extraCount))) {
          if (step === "list") {
            state.selected.seq = "";
            item.optionText = "";
          }

          window.alert("수량이 옵션 재고를 초과했습니다.");
          return false;
        }
      }

      return true;
    };

    const addItem = (item, sel) => {
      if (!computedAvailAble.value) {
        return;
      }

      if (isRewardSoldOut(item.rewardQty, item.extraCount)) {
        return;
      }

      if (item.rewardSeq === null) {
        return router.push(`/reward/${router.app.$route.params.projectSeq}/order`);
      }

      if (item.rewardSelFlag === "Y" && !sel) {
        state.swiped = false;
        state.selected.seq = item.rewardSeq;

        nextTick(() => {
          const $elem = document.querySelector(`li[data-reward-seq="${item.rewardSeq}"]`);
          item.rewardOptionItems?.length ? $elem?.querySelector(".wrapper .actions select")?.focus() : $elem?.querySelector(".wrapper .actions textarea")?.focus();
        });

        return;
      }

      if (item.rewardSelFlag === "Y" && sel && !item.optionText) {
        return window.alert("리워드 옵션을 선택해주세요");
      }

      if (state.selected.items.length && !calcQuantity(item, "list")) {
        return;
      }

      const exist = state.selected.items.find(i => i.rewardSeq === item.rewardSeq && i.optionText === item.optionText);

      if (exist !== undefined) {
        item.optionText = "";
        state.swiped = true;
        state.selected.seq = "";
        return exist.count += 1;
      }

      const selectedItem = {
        title: item.title,
        rewardSeq: item.rewardSeq,
        rewardAmt: item.rewardAmt,
        count: item.count,
        rewardQty: item.rewardQty,
        extraCount: item.extraCount,
        optionText: item.optionText
      };

      if (item.rewardSelFlag === "Y" && item.optionText) {
        selectedItem.rewardSelFlag = item.rewardSelFlag || "";
        selectedItem.rewardOptionItems = item.rewardOptionItems || [];
      }

      state.selected.items.unshift(selectedItem);
      state.selected.seq = "";
      state.swiped = true;
      item.optionText = "";
    };

    const removeItem = (idx) => {
      state.selected.items.splice(idx, 1);
    };

    const isRewardSoldOut = (rewardQty, extraCount) => {
      return rewardQty > 0 && extraCount <= 0;
    };

    const submit = () => {
      const projectSeq = router.app.$route.params.projectSeq;
      const items = [];

      const warn = (rewardSeq, message) => {
        focusItemOption(rewardSeq);
        window.alert(message);
      };

      if (computedAmount.value === 0) {
        window.alert("후원하실 리워드를 선택해주세요.");
        return;
      }

      for (const item of state.selected.items) {
        if (item.count > getMaxCount(item)) {
          return warn(item.rewardSeq, "리워드 수량을 남은 재고 수량 이하로 선택해주세요.");
        }

        if (item.rewardSelFlag === "Y") {
          if (item.rewardOptionItems.length) {
            if (!item.optionText) {
              focusItemOption(item.rewardSeq);
              return warn(item.rewardSeq, "리워드 옵션을 선택해주세요.");
            }
          } else if (!item.optionText) {
            focusItemOption(item.rewardSeq);
            return warn(item.rewardSeq, "리워드 정보를 입력해주세요.");
          } else if (item.optionText.length > 100) {
            return warn(item.rewardSeq, "리워드 정보를 100자 이하로 입력해주세요.");
          }
        }

        items.push({
          rewardSeq: item.rewardSeq,
          count: item.count,
          optionText: item.optionText
        });
      }

      const itemsJson = JSON.stringify(items);
      const rewardsBase64 = lib.getBase64UrlSafeEncoded(itemsJson);

      window.location.href = `/reward/${projectSeq}/order?rewards=${rewardsBase64}`;
    };

    return {
      component
      , state
      , computedAmount
      , computedAvailAble
      , computedTotalCount
      , getOptionExtraCount
      , getCount
      , increase
      , submit
      , toggleSwipe
      , addItem
      , removeItem
      , isRewardSoldOut
    };
  }
});
</script>

<style lang="scss" scoped>
.rewards {
  > .wrapper {
    display: flex;
    transition: transform 0.18s;

    > ul {
      width: 100%;
      flex-shrink: 0;

      > li {
        cursor: pointer;

        .wrapper {
          background: #fff;
          border: $px1 solid #f2f2f2;
          border-radius: $px20;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: $px16;
          padding: $px16;
          transition: background-color 0.18s;

          .main {
            .count {
              > .img {
                width: $px16;
                height: $px16;
              }
            }
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .btn {
              padding: $px8 $px24;
              border-radius: $px16;
            }
          }

          .check {
            display: flex;
            gap: $px16;
            align-items: flex-start;

            .subject {
              display: flex;
              align-items: center;
              gap: $px4;
              flex-shrink: 0;
              width: $px60;

              > .img {
                width: $px16;
                height: $px16;
              }
            }

            ul li {
              &:not(:first-child) {
                margin-top: $px8;
              }
            }
          }

          .actions {
            select {
              background-color: #fff;
              border-color: $colorBorder;
              border-radius: $px16;
              height: $formHeight;
            }

            .textarea {
              display: flex;
              align-items: center;
              gap: $px16;

              textarea {
                background-color: #fff;
                height: $formHeight;
                padding-top: $px12;
                padding-bottom: $px12;
                border-radius: $px16;
                border-color: $colorBorder;
              }

              > .btn {
                flex-shrink: 0;
                border-radius: $px16;
                padding: $px8 $px16;
                height: $formHeight;
              }
            }
          }

          &.selected {
            border-color: $colorPoint;
          }

          &:hover {
            border-color: $colorPoint;
          }
        }

        &.sold-out {
          cursor: initial;
          opacity: 0.6;

          > .wrapper {
            background-color: $colorBackground;

            .main .count {
              color: inherit !important;

              > .img {
                background-image: url(/assets/ico/common.smile.svg) !important;
              }
            }

            &:hover {
              border-color: #f2f2f2;
              background-color: $colorBackground;
            }
          }
        }

        &.disable {
          cursor: initial;

          > .wrapper {
            &:hover {
              background: #fff;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: $px20;
        }
      }
    }

    .sheet {
      background: #fff;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 100%;

      > .actions {
        > .btn {
          border-radius: $px16;
          width: 100%;
          padding: $px16;

          &.btn-light {
            &:not(:first-child) {
              margin-top: $px8;
            }
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-top: $px32;

        ul {
          overflow: auto;

          li {
            background: $colorBackground;
            border-radius: $px16;
            padding: $px16;

            > .title {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              gap: $px32;

              b {
                word-break: keep-all;
              }

              > .img {
                cursor: pointer;
                width: $px12;
                height: $px12;
                margin-top: $px6;
                flex-shrink: 0;
              }
            }

            .switch {
              > .btn {
                border-radius: 50%;
                padding: 0;
                width: $px32;
                height: $px32;

                &:disabled {
                  background: $colorBackground;
                  color: $colorSecondary;
                }
              }

              > span {
                padding: 0 $px10;
              }
            }

            &:not(:last-child) {
              margin-bottom: $px16;
            }
          }
        }
      }
    }

    &.swiped {
      transform: translateX(-100%);
    }
  }
}
</style>