<template>
  <div class="detail-community-review board" :class="{skeleton: !state.review.loaded}" ref="contentRef">
    <div class="write section" v-if="new Date(investEndDate) < new Date()">
      <div>
        <span class="emoji" :style="{backgroundImage: `url('/assets/ico/component.reviewtagbar.pencil.png')`}"></span>
        <div class="text">
          <span>프로젝트 후기 작성</span>
          <span class="desc" v-if="$store.state.account.loggedIn">받으신 리워드 만족도 등 후기를 남겨주세요.</span>
          <span class="desc" v-else>로그인 후 작성해주세요.</span>
        </div>
      </div>
      <button class="btn btn-bordered-point action" @click="open()">{{ $store.state.account.loggedIn ? "후기 작성" : "로그인" }}</button>
    </div>
    <template v-if="!state.review.loaded || state.review.list.length">
      <ReviewTagBar
          classType="detail"
          :url="`/api/project/${$route.params.projectSeq}/review/tags`"
          :overflow="true"
          :skeleton="!state.review.loaded"
      />
      <div>
        <ReviewCard
            v-for="(r,idx) in state.review.list" :key="idx"
            :skeleton="!state.review.loaded"
            :builderSeq="openId"
            :content="r.content"
            :createDate="r.createDate"
            :delFlag="r.delFlag"
            :hideInvestorNameYn="r.hideInvestorNameYn"
            :reviewId="r.id"
            :replies="r.replies"
            :memberSeq="r.memberSeq"
            :memberName="r.memberName"
            :photos="r.photos"
            :selectedTags="r.selectedTags"
            :updateDate="r.updateDate"
            :blindFlag="r.blindFlag"
        />
      </div>
      <PaginationJpa :pageable="state.review.pageable" :total="state.review.total" :change="load" v-if="state.review.pageable.size"/>
    </template>
    <NoData text="등록된 후기가 없습니다." v-else/>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive, ref} from "@vue/composition-api";
import NoData from "@/components/NoData";
import http from "@/scripts/http";
import ReviewCard from "@/components/ReviewCard";
import router from "@/scripts/router";
import Pagination from "@/components/Pagination";
import store from "@/scripts/store";
import ReviewTagBar from "@/components/ReviewTagBar";
import PaginationJpa from "@/components/PaginationJpa";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageProjectDetailCommunityReview";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, ReviewTagBar, Pagination, ReviewCard, NoData},
  mixins: [mixin],
  props: {
    projectSeq: Number,
    projectType: String,
    openId: String,
    investEndDate: String,
    investRewards: Array,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      review: {
        loaded: false,
        pageable: {
          page: 0,
          size: 0,
        },
        total: null,
        list: [],
      },
      args: {
        page: 0,
        size: 5,
        sort: "createDate,desc"
      },
      investRewards: [],
    });

    const contentRef = ref();

    const load = (page, scrollTo) => {
      state.review.loaded = false;
      state.review.list = [];
      state.args.page = page || 0;

      if (contentRef.value && !scrollTo) {
        window.scrollTo({
          top: contentRef.value.getBoundingClientRect().top + window.scrollY - lib.getHeaderHeight() - 140,
          left: 0,
          behavior: "smooth"
        });
      }

      for (let i = 0; i < 5; i += 1) {
        state.review.list.push({
          skeleton: true,
          content: "Wait a moment",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          selectedTags: "wait,wait,wait,wait,wait",
        });
      }

      http.get(`/api/project/${router.app.$route.params.projectSeq}/reviews`, state.args).then(({data}) => {
        state.review.loaded = true;
        state.review.list = data.body.content;
        state.review.pageable = data.body.pageable;
        state.review.total = data.body.total;

        state.investRewards = props.investRewards;
      });
    };

    const open = () => {
      for (let i in state.investRewards) {
        if (state.investRewards[i].title.includes("없이 참여")) {
          state.investRewards.splice(i, 1);
        }
      }

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      } else if (!props.investRewards?.length) {
        return store.commit("confirm", {
          message: "작성 가능한 후기가 없습니다.",
          subMessage: "리워드 없이 참여 시에도 후기를 작성할 수 없습니다.",
          allowTxt: "작성 가능한 후기 보러가기",
          disallowTxt: "닫기",
          allow() {
            router.replace({path: "/mypage/review/unreviewed"});
          }
        });
      }

      store.commit("confirm", {
        message: "리워드를 받으셨나요? 꼭 받으신 후에 작성해주세요!",
        allowTxt: "받았어요",
        disallowTxt: "아직 받지 못했어요",
        allow() {
          store.commit("openModal", {
            name: "Review",
            params: {
              projects: true,
              projectSeq: props.projectSeq,
              list: state.investRewards,
            }
          });
        },
        disallow() {
          store.commit("setSwingMessage", "리워드를 받으신 후에 후기를 작성해주세요.");
        }
      });
    };

    return {component, state, contentRef, load, open};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community-review {
  .section {
    margin-bottom: $px30;

    span {
      vertical-align: top;

      &.emoji {
        margin-right: $px10;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        top: $px4;
        width: $px20;
        height: $px20;
        display: inline-block;
      }
    }

    .text {
      display: inline-block;

      .desc {
        display: block;
        font-size: $px12;
        color: #aaa;
      }
    }

    &.write {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        font-size: $px13;
        padding: $px10 $px20;
      }
    }

    &.skeleton {
      .write {
        > div {
          > span, .text > span {
            @include skeleton;
          }
        }

        > button {
          @include skeleton;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section {
      &.write {
        flex-direction: column;
        align-items: flex-start;

        .action {
          width: 100%;
          margin-top: $px15;
        }
      }
    }
  }
}

</style>