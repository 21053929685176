<template>
  <div class="project-detail-join-btn">
    <template v-if="loaded">
      <template v-if="projectType === 'reward'">
        <button class="btn btn-point-soft" :id="submitBtnId" @click="submit()" v-if="progressOrder === 1 || $lib.isPreviewPage()">
          <b class="txt pr-1" v-if="paymentAmount">{{ $lib.getNumberFormat(paymentAmount) }}원</b>
          <b class="txt">{{ mockFlag === "Y" ? "투자하기" : "후원하기" }}</b>
        </button>
        <button class="finished btn btn-point-soft" disabled v-else>
          <b class="txt">{{ successFailFlag === "S" ? "프로젝트 성공" : "프로젝트 마감" }}</b>
        </button>
      </template>
      <template v-else-if="projectType === 'invest'">
        <button class="btn btn-point-soft" @click="investSubmit('finished')" v-if="Number(subscribeRate) >= 80 && endFlag === 'Y'">
          <b class="txt">투자 성공</b>
        </button>
        <button class="btn btn-point-soft" @click="investSubmit('finished')" v-else-if="Number(subscribeRate) < 80 && endFlag === 'Y'">
          <b class="txt">투자 마감</b>
        </button>
        <button class="btn btn-point-soft" @click="investSubmit('preparing')" v-else-if="displayStateName === '펀딩준비중' || displayStateName === '펀딩대기중'">
          <b class="txt">펀딩 대기 중</b>
        </button>
        <button class="btn btn-point-soft" @click="investSubmit('confirm')" v-else-if="subscribeAmount > totAmount && endFlag === 'N'">
          <b class="txt">투자하기</b>
        </button>
        <button class="btn btn-point-soft" @click="submit()" v-else>
          <b class="txt">투자하기</b>
        </button>
      </template>
    </template>
    <template v-else>
      <button class="btn"></button>
    </template>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentProjectDetailJoinBtn";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    loaded: Boolean,
    projectType: String,
    progressOrder: Number,
    submit: Function,
    investSubmit: Function,
    mockFlag: String,
    successFailFlag: String,
    paymentAmount: Number,
    subscribeRate: String,
    endFlag: String,
    displayStateName: String,
    subscribeAmount: Number,
    totAmount: Number,
    submitBtnId: String,
  },
  setup() {
    const component = new Component(() => {
    });

    return {
      component,
    };
  },
});
</script>

<style lang="scss" scoped>
.project-detail-join-btn {
  .btn {
    border: 0;
    border-radius: $px16;
    font-size: $px16;
    position: relative;
    opacity: 1;
    height: 100%;
    width: 100%;
    padding: $px16;

    .txt {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
    }

    &.finished {
      cursor: default;
    }
  }
}
</style>