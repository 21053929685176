<template>
  <div class="detail-intro-story">
    <div class="story">
      <div class="title" v-if="step.name !== 'fundingIntro'">
        <span>{{ step.title }}</span>
      </div>
      <div class="wrapper" v-for="(s, idx) in stories" :key="idx">
        <div class="images" v-if="s.files && s.files.length">
          <img :src="f.filePath + f.fileSaveName" :alt="s.title + (idx + 1)" v-for="(f, idx) in s.files" :key="idx"/>
        </div>
        <div class="points" v-if="step.name === 'fundingPoint'">
          <p class="point">
            <span>{{ idx + 1 }}.</span>
            <span>{{ s.content }}</span>
          </p>
        </div>
        <div class="reward shadow" v-else-if="step.name === 'rewardConstructor'">
          <div class="price">
            <span>{{ lib.getNumberFormat(s.rewardAmt) }} 원</span>
          </div>
          <span class="title">{{ s.title }}</span>
          <div class="bottom">
            <div class="wrapper">
              <div class="subject">
                <span class="img mr-1" style="background-image: url(/assets/ico/common.check.svg)"></span>
                <b>배송</b>
              </div>
              <span>{{ s.rewardExpectText }}</span>
            </div>
            <div class="wrapper" v-if="s.rewardOptionItems.length">
              <div class="subject">
                <span class="img mr-1" style="background-image: url(/assets/ico/common.check.svg)"></span>
                <b>옵션</b>
              </div>
              <div class="options">
                <span v-for="o in s.rewardOptionItems" :key="o.rewardOptionSeq">{{ o.rewardOptionText }}</span>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="title" v-if="s.title"><span>{{ s.title }}</span></div>
          <div class="content" v-html="s.content"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import DetailIntroComments from "./DetailIntroComments.vue";
import mixin from "@/scripts/mixin";
import lib from "../../scripts/lib";

function Component(initialize) {
  this.name = "pageProjectDetailIntroStory";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    lib() {
      return lib;
    }
  },
  mixins: [mixin],
  components: {DetailIntroComments},
  props: {
    step: {
      title: String
    },
    stories: Array,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
.detail-intro-story {
  > .story {
    > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 600;
        font-size: $px20;
      }

      + .wrapper {
        padding-top: $px16;
      }
    }

    > .wrapper {
      > .title {
        font-size: $px18;
        font-weight: 600;
        margin-bottom: $px32;
      }

      .images {
        img {
          width: 100%;
          margin-bottom: $px16;

          &:last-child {
            margin-bottom: $px36;
          }
        }
      }

      .points {
        .point {
          display: flex;

          > span {
            &:first-child {
              width: $px24;
            }
          }
        }
      }

      .reward {
        display: flex;
        flex-direction: column;
        gap: $px16;
        padding: $px24 $px20;
        border-radius: $px24;
        font-weight: 400;

        .price span {
          font-weight: 600;
          font-size: $px20;
          word-break: break-word;
        }

        > .title {
          word-break: break-word;
          font-size: $px16;
        }

        > .bottom {
          display: flex;
          flex-direction: column;
          gap: $px16;
          width: 100%;
          font-size: $px13;

          > .wrapper {
            word-break: break-word;
            display: flex;
            gap: $px16;

            .subject {
              flex-shrink: 0;

              > .img {
                vertical-align: text-bottom;
                width: $px16;
                height: $px16;
              }
            }

            b, span {
              font-size: $px14;
            }

            .options {
              span {
                display: block;

                &:not(:last-child) {
                  padding-bottom: $px8;
                }
              }
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: $px16;
      }
    }
  }

  @media (max-width: 767px) {
    > .story {
      > .wrapper {
        .reward {
          .price span {
            font-size: $px18;
          }

          > .title {
            font-size: $px14;
          }
        }
      }
    }
  }
}
</style>